<template>
  <div class="footer">
    <div class="copyright">
      Copyright &copy; {{ new Date().getFullYear() }} RoofOverYourHead Marketing Ltd. All rights reserved.
    </div>
    <div class="build-info">
      (build: {{ buildInfo.branch }} {{ buildInfo.hash }})
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  created () {
    if (process.env.BUILD_INFO) {
      this.buildInfo = JSON.parse(process.env.BUILD_INFO)
    }
  }
}
</script>

<style scoped>
.footer {
  text-align: center;
}
.copyright {
  padding: 0.25em;
  font-size: 0.85em;
}
.build-info {
  font-size: 0.75em
}
</style>
