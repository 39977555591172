<template>
  <ol class="breadcrumb">
    <li v-for="(item, index) in linkItems" :key="index">
      <router-link v-if="!item.disabled" :to="item.to">
        {{ item.text }}
      </router-link>
      <span
        v-else
        class="disabled"
        v-text="item.text"
      />
    </li>
    <li v-if="lastItem">
      <!-- span added to enable text styling through classes -->
      <span class="last-item" v-text="lastItem.text" />
    </li>
    <slot />
  </ol>
</template>

<script>
/**
 * Breadcrumb
 */
export default {
  name: 'Breadcrumb',
  props: {
    items: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    lastItem () {
      return this.items ? this.items[this.items.length - 1] : null
    },
    linkItems () {
      return this.items ? this.items.slice(0, -1) : []
    }
  }
}
</script>

<style scoped>
ol {
  display: flex;
  list-style: none;
  padding-left: 0;
}
li {
  color: #8d8d8d;
  font-size: 14px;
}
li + li:before {
  padding: 8px 4px 8px 8px;
  content: "/\00a0"; /* forward slash */
}
li a {
  display: inline-block; /* remove whitespace */
  color: #0275d8;
  text-decoration: none;
}
li a:hover {
  color: #0058a5;
  text-decoration: underline;
}
.disabled {
  color: #8d8d8d;
}
</style>
