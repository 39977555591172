<template>
  <div class="sidebar">
    <el-menu
      :router="true"
      :default-active="activeIndex"
      :collapse="!sidebarOpen"
    >
      <div class="logo-container">
        <router-link :to="{ name: 'Root' }">
          <rooof-logo
            v-if="sidebarOpen"
            class="logo-bg"
          />
          <rooof-icon
            v-else
            class="logo-sm"
          />
        </router-link>
      </div>

      <div v-if="hasStaffPermission">
        <el-menu-item index="companies" :route="{ name: 'CompanyList' }">
          <i class="el-icon-s-home" />
          <span slot="title">Companies</span>
        </el-menu-item>

        <el-menu-item index="rooofAccounts" :route="{ name: 'RooofAccounts' }">
          <i class="el-icon-user-solid" />
          <span slot="title">Rooof Accounts</span>
        </el-menu-item>

        <el-menu-item index="craigslistAccounts" :route="{ name: 'CraigslistAccounts' }">
          <peace-symbol-icon class="el-icon menu-icon" />
          <span slot="title">Craigslist Accounts</span>
        </el-menu-item>

        <el-menu-item index="alerts" :route="{ name: 'Alerts' }">
          <i class="el-icon-message-solid" />
          <span slot="title">Alerts</span>
        </el-menu-item>

        <el-menu-item index="invoices" :route="{ name: 'Invoices' }">
          <dollar-icon class="el-icon menu-icon dollar" />
          <span slot="title">Invoices</span>
        </el-menu-item>

        <el-menu-item index="internalReports" :route="{ name: 'InternalReports' }">
          <bar-graph-icon class="el-icon menu-icon bar-graph" />
          <span slot="title">Internal Reports</span>
        </el-menu-item>
      </div>

      <div v-if="hasStaffPermission || isCaasPoster">
        <el-menu-item index="craigslistFullService" :route="{ name: 'CraigslistFullService' }">
          <i class="el-icon-finished" />
          <span slot="title">Craigslist Full Service</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PeaceSymbolIcon from '@/assets/icons/svg/peace_symbol.svg'
import DollarIcon from '@/assets/icons/svg/dollar.svg'
import BarGraphIcon from '@/assets/icons/svg/file_bar_graph.svg'
import RooofLogo from '@/assets/icons/svg/rooof_logo.svg'
import RooofIcon from '@/assets/icons/svg/rooof_icon.svg'

export default {
  name: 'SideBar',
  components: {
    'peace-symbol-icon': PeaceSymbolIcon,
    'dollar-icon': DollarIcon,
    'bar-graph-icon': BarGraphIcon,
    'rooof-logo': RooofLogo,
    'rooof-icon': RooofIcon
  },
  computed: {
    ...mapState({
      sidebarOpen: state => state.sidebarOpen
    }),
    ...mapGetters({
      hasStaffPermission: 'auth/isStaff',
      isCaasPoster: 'auth/isCaasPoster'
    }),
    activeIndex () {
      const matched = this.$route.matched.slice().reverse().find(el => {
        return el.meta.sidebarNavIndex
      })
      if (!matched) {
        throw new Error('Unable to find sidebarNavIndex in matching routes')
      }
      return matched.meta.sidebarNavIndex
    }
  }
}
</script>

<style>
.el-menu:not(.el-menu--collapse) {
  width: 200px;
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/rooof-variables.scss";

.sidebar {
  height: 100%;
  padding-top: 2px;
  box-sizing: border-box;
  background-color: $--sidebar-background-color;

  .el-menu {
    border-right: none;
    background-color: inherit;

    .el-menu-item {
      color: #fff;

      &:hover {
        background-color: $--sidebar-hover-background-color;
      }
    }

    .el-menu-item.is-active {
      color: #fff;
      background-color: $--sidebar-active-background-color;
    }

    i {
      color: $--sidebar-icon-color;
    }

    .menu-icon {
      fill: $--sidebar-icon-color;
      margin-left: 2px;
      margin-right: 8px;
      height: 18px;

      &.dollar, &.bar-graph {
        width: 24px;
        margin-left: 0;
      }
    }
  }
}
.logo-container {
  text-align: center;

  .logo-bg {
    max-width: 100%;
    height: 42px;
  }
  .logo-sm {
    max-width: 64px;
    height: 42px;
  }
}
</style>
