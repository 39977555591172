<template>
  <div class="header">
    <div class="toggle-sidebar-container" @click="toggleSidebar()">
      <menu-icon :class="['toggle-sidebar-icon', { 'is-active': sidebarOpen }]" />
    </div>
    <div class="breadcrumb-container">
      <breadcrumb-router />
    </div>
    <div class="dropdown-container">
      <el-dropdown trigger="click">
        <span class="avatar-container">
          <account-icon class="avatar" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <div class="menu-item">
            <div>Role:</div>
            <div><b>{{ role }}</b></div>
          </div>
          <div class="menu-item">
            <div>Authenticated:</div>
            <div><b>{{ isAuthenticated }}</b></div>
          </div>
          <div class="menu-item">
            <div>Token Expires:</div>
            <div><b>{{ expiresAt }}</b></div>
          </div>
          <div class="menu-item log-out">
            <el-button id="logout-btn" @click="handleLogout()">
              Log Out
            </el-button>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'

import BreadcrumbRouter from '@/components/breadcrumbs/BreadcrumbRouter'

import AccountIcon from '@/assets/icons/svg/account_box.svg'
import MenuIcon from '@/assets/icons/svg/indent_increase.svg'

export default {
  name: 'Header',
  components: {
    'breadcrumb-router': BreadcrumbRouter,
    'account-icon': AccountIcon,
    'menu-icon': MenuIcon
  },
  computed: {
    role () {
      return this.$store.getters['auth/isStaff'] ? 'staff' : 'user'
    },
    ...mapState({
      sidebarOpen: state => state.sidebarOpen,
      expiresAt: state => moment(state.auth.expiresAt).calendar()
    }),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    })
  },
  methods: {
    /**
     * Toggle the sidebar state.
     */
    toggleSidebar () {
      this.$store.dispatch('toggle_sidebar')
    },
    /**
     * Log out the current user.
     */
    handleLogout () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/rooof-variables.scss";

.header {
  display: flex;
  align-items: center;
  border-bottom: $--header-border-botttom;
}
.toggle-sidebar-container {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: #00000010
  }

  .toggle-sidebar-icon {
    fill: $--header-icon-color;
  }

  .toggle-sidebar-icon.is-active {
    transform: rotate(180deg);
  }
}
.dropdown-container {
  margin-left: auto;
  padding-right: 20px;

  .avatar-container {
    top: 2px;
    position: relative;

    .avatar {
      fill: $--header-icon-color;
      width: 40px;
      height: 40px;
      cursor: pointer;

      &:hover {
        fill: $--header-icon-hover-color;
      }
    }
  }
}
.menu-item {
  padding: 0.5em 1em;
}
.log-out {
  text-align: right;
}
</style>
