<template>
  <div v-loading.fullscreen.lock="loading">
    <el-container v-if="!loading && isLoggedIn" id="app">
      <el-aside id="sidebar" width="auto">
        <rf-navigation />
      </el-aside>
      <el-container>
        <el-header id="header" height="50px">
          <rf-header />
        </el-header>
        <el-main id="main">
          <router-view />
        </el-main>
        <el-footer id="footer" height="auto">
          <rf-footer />
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
/**
 * Default dashboard layout for authenticated views.
 */
import Header from './_components/TheHeader'
import Footer from './_components/TheFooter'
import SideBar from './_components/TheSideBar'

export default {
  name: 'DashView',
  components: {
    'rf-header': Header,
    'rf-footer': Footer,
    'rf-navigation': SideBar
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['auth/isAuthenticated']
    }
  },
  async created () {
    try {
      this.loading = true
      await Promise.all([
        this.$store.dispatch('fetch_company_list'),
        this.$store.dispatch('fetch_craigslist_regions'),
        this.$store.dispatch('fetch_product_list'),
        this.$store.dispatch('fetch_feature_list'),
        this.$store.dispatch('auth/fetchAccount')
      ])
    } catch (err) {
      const details = err.response ? err.response.data : null
      this.$rfAlert.error(this, err.toString(), details)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
#header, #footer {
  padding: 0;
}
#footer {
  color: #888;
}
</style>
